/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import { Edit, Trash2 } from 'feather-icons-react';
import { useEffect, useState } from 'react';
import { capitalize } from 'utils';

import { Button, Grid, Modal, Spin } from 'components/common';

import { useAuth } from 'modules/auth/useAuth';
import { useOrganization } from 'modules/organization/useOrganization';
import { useUser } from 'modules/user/useUser';
import { Status, User, UserRoles } from 'modules/user/userApiTypes';

import { DeleteMemberModal } from './DeleteMemberModal';
import { EditMembersModal } from './EditMembersModal';
import { InviteMembersModal } from './InviteMembersModal';

export const MembersSettings = () => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<undefined | User>(undefined);
  const { members, getMembers } = useUser();
  const { currentAgencyId, isLoading } = useOrganization();
  const { role } = useAuth();

  const openInviteModal = () => setIsInviteModalOpen(true);
  const closeInviteModal = () => setIsInviteModalOpen(false);

  const openEditModal = () => setIsEditModalOpen(true);
  const closeEditModal = () => setIsEditModalOpen(false);

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const canEdit = role === UserRoles.SUPER_ADMIN.value || role === UserRoles.INTERNAL_ADMIN.value;

  useEffect(() => {
    currentAgencyId && getMembers(currentAgencyId);
  }, [currentAgencyId]);

  const handleEditClick = (id: GridRowId) => () => {
    const user = members?.find((el) => el.id === id);

    setSelectedUser(user);
    openEditModal();

    return id;
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const user = members?.find((el) => el.id === id);

    setSelectedUser(user);
    openDeleteModal();

    return id;
  };

  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Member',
      flex: 2,
      headerClassName: 'member-cell',
      minWidth: 100,
      renderCell: (params) => {
        const member = params.row;

        return (
          <div className="member-container leading-4 -mt-1">
            <div className="member-surname text-base-sm font-normal">
              {member?.fullName ? member?.fullName : member?.email}
            </div>
            <div className="member-email text-base-xs text-primary-gray-300">
              {member?.fullName ? member?.email : ''}
            </div>
          </div>
        );
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      minWidth: 100,
      valueGetter: (value) => UserRoles[value]['label'] || '-',
    },
    // {
    //   field: 'advertiser',
    //   headerName: 'Assigned account',
    //   renderCell: (params) => {
    //     const member = params.row;

    //     return (
    //       <div className="text-base-sm font-normal">
    //         {member?.role !== UserRoles.LIMITED_ACCESS.value ? '-' : member?.advertiser?.name}
    //       </div>
    //     );
    //   },
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const status = params.value as Status;

        return <span className={`status-cell ${status.toLowerCase()}`}>{capitalize(status)}</span>;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      cellClassName: 'actions',
      flex: 1,
      align: 'right',
      getActions: ({ id, row }) =>
        canEdit
          ? [
              <GridActionsCellItem
                icon={<Edit />}
                key={id}
                label="Edit"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                key={id}
                icon={<Trash2 />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
                disabled={row.role === UserRoles.SUPER_ADMIN.value}
              />,
            ]
          : [],
    },
  ];

  //REMOVE
  //REMOVE
  const membersWithoutSellers = members?.filter(
    (m) => m.role !== UserRoles.COOL_MEDIA_SELLER.value,
  );

  return (
    <div className="w-screen h-full bg-white rounded-xl border border-primary-gray-100 flex-col justify-start items-start flex">
      <div className="p-8 flex-col justify-start items-start inline-flex w-full">
        <div className="flex-col w-full justify-start items-start gap-4 flex">
          <div className="text-secondary-black text-heading-xl font-semibold leading-relaxed">
            Members
          </div>
          {canEdit ? (
            <Button variant="primary" size="md" onClick={openInviteModal}>
              Invite new member(s)
            </Button>
          ) : null}

          <Spin spinning={isLoading}>
            <div className="grid w-full">
              <Grid columns={columns} rows={membersWithoutSellers as any[] | undefined} />
            </div>
          </Spin>
        </div>
      </div>
      <Modal
        open={isInviteModalOpen}
        footer={null}
        closable={false}
        onCancel={closeInviteModal}
        destroyOnClose={true}
      >
        <InviteMembersModal onClose={closeInviteModal} />
      </Modal>
      <Modal open={isEditModalOpen} footer={null} closable={false} onCancel={closeEditModal}>
        <EditMembersModal onClose={closeEditModal} user={selectedUser} />
      </Modal>
      <Modal open={isDeleteModalOpen} footer={null} closable={false} onCancel={closeDeleteModal}>
        <DeleteMemberModal onClose={closeDeleteModal} user={selectedUser} />
      </Modal>
    </div>
  );
};
