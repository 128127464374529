import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from 'utils';

import { getCurrentAgencyId } from 'modules/organization/organizationSlice';

import { userApi } from './userApi';
import {
  MembersPagingRequestValue,
  MembersPagingResponse,
  UpdateMemberRequestValues,
  UserInviteRequestValues,
} from './userApiTypes';
import {
  inviteUserSuccess,
  inviteUserFailure,
  inviteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  deleteUserStart,
  getMembersStart,
  getMembersSuccess,
  getMembersFailure,
  updateMemberSuccess,
  updateMemberFailure,
  updateMemberStart,
} from './userSlice';

function* inviteUserFlow(action: PayloadAction<UserInviteRequestValues>) {
  try {
    const payload = action.payload;

    yield call(userApi.inviteUser, payload);
    yield put(inviteUserSuccess());
  } catch (error) {
    yield put(inviteUserFailure(getErrorMessage(error)));
  }
}

function* getMembersFlow(action: PayloadAction<MembersPagingRequestValue>) {
  try {
    const payload = action.payload;
    const response: MembersPagingResponse = yield call(userApi.getMembers, payload);

    yield put(getMembersSuccess(response.data));
  } catch (error) {
    yield put(getMembersFailure(getErrorMessage(error)));
  }
}

function* deleteUserFlow(action: PayloadAction<string>) {
  try {
    const payload = { page: 0, size: 10000, agencyId: '' };
    const currentAgencyId: string = yield select(getCurrentAgencyId);

    payload.agencyId = currentAgencyId;
    yield call(userApi.deleteUser, action.payload);
    const response: MembersPagingResponse = yield call(userApi.getMembers, payload);

    yield put(deleteUserSuccess(response.data));
  } catch (error) {
    yield put(deleteUserFailure(getErrorMessage(error)));
  }
}

function* updateMemberFlow(action: PayloadAction<UpdateMemberRequestValues>) {
  try {
    const payload = { page: 0, size: 10000, agencyId: action.payload.currentSelectedAgencyId };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { currentSelectedAgencyId: _, ...rest } = action.payload;

    yield call(userApi.updateMember, rest);
    const response: MembersPagingResponse = yield call(userApi.getMembers, payload);

    yield put(updateMemberSuccess(response.data));
  } catch (error) {
    yield put(updateMemberFailure(getErrorMessage(error)));
  }
}

export function* userSagas() {
  yield all([
    takeLatest(inviteUserStart.type, inviteUserFlow),
    takeLatest(deleteUserStart.type, deleteUserFlow),
    takeLatest(updateMemberStart.type, updateMemberFlow),
    takeLatest(getMembersStart.type, getMembersFlow),
  ]);
}
