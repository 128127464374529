import { ReactComponent as IconBook } from 'assets/images/icons/book-open.svg';
import { ReactComponent as IconBuilding } from 'assets/images/icons/building.svg';
import { ReactComponent as IconCheckVerified } from 'assets/images/icons/check-verified.svg';
import { ReactComponent as IconCodeBrowser } from 'assets/images/icons/code-browser.svg';
import { ReactComponent as IconLayout } from 'assets/images/icons/layout-alt.svg';
import { ReactComponent as IconLink } from 'assets/images/icons/link.svg';
import { ReactComponent as IconUserSquare } from 'assets/images/icons/user-square.svg';
import { ReactComponent as IconUsers } from 'assets/images/icons/users.svg';

import { NavItem, TabKeys } from 'components/Sidebar/Sidebar';

import { UserRoles, RoleKey } from 'modules/user/userApiTypes';

import { Route, ROUTES, EXTERNAL_LINKS } from 'routing/constants';

export const menuTabsConfig: { [key in TabKeys]: NavItem[] } = {
  [TabKeys.DASHBOARD]: [],
  [TabKeys.REPORT_BUILDER]: [],
  [TabKeys.PIXEL]: [
    { iconName: IconCodeBrowser, label: 'Pixel code', linkTo: ROUTES.PIXEL_CODE },
    { iconName: IconCheckVerified, label: 'Pixel verification', linkTo: ROUTES.PIXEL_VERIFICATION },
    {
      iconName: IconBook,
      label: 'Installation guide',
      href: EXTERNAL_LINKS.PIXEL_INSTALLATION,
    },
  ],
  [TabKeys.SETTINGS]: [
    { iconName: IconBuilding, label: 'Agency', linkTo: ROUTES.AGENCY_SETTINGS },
    { iconName: IconUserSquare, label: 'User', linkTo: ROUTES.USER_SETTINGS },
    { iconName: IconLayout, label: 'Advertisers', linkTo: ROUTES.ADVERTISER_SETTINGS },
    { iconName: IconUsers, label: 'Members', linkTo: ROUTES.MEMBERS_SETTINGS },
    { iconName: IconLink, label: 'Xandr connection', linkTo: ROUTES.XANDR_SETTINGS },
  ],
};

export const restrictedRoutes: Record<RoleKey, Route[]> = {
  [UserRoles.SUPER_ADMIN.value]: [],
  [UserRoles.INTERNAL_ADMIN.value]: [],
  [UserRoles.ADMIN.value]: [
    ROUTES.AGENCY_SETTINGS,
    ROUTES.ADVERTISER_SETTINGS,
    ROUTES.XANDR_SETTINGS,
  ],
  [UserRoles.COOL_MEDIA_SELLER.value]: [
    ROUTES.AGENCY_SETTINGS,
    ROUTES.ADVERTISER_SETTINGS,
    ROUTES.MEMBERS_SETTINGS,
    ROUTES.XANDR_SETTINGS,
  ],
  [UserRoles.LIMITED_ACCESS.value]: [
    ROUTES.AGENCY_SETTINGS,
    ROUTES.ADVERTISER_SETTINGS,
    ROUTES.MEMBERS_SETTINGS,
    ROUTES.XANDR_SETTINGS,
  ],
};
